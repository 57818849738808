import Spacer from "./spacer";
import Typography from "./typography";
import styled from "styled-components";

const StyledInput = styled.input`
  font-size: 1.1rem;
  flex: 1;
  height: 100%;
  text-align: end;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const ContainerInput = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  background-color: white;
  border-radius: 4px 4px 0 0;
  height: 50px;
  padding: 0 16px;
`;

const ContainerFlex = styled.div`
  display: flex;
`;

const Input = ({ hint, label, unit, ...props }) => (
  <>
    <ContainerInput>
      <Spacer spacing={0.5} />
      {label && (
        <Typography color="textSecondary" size="0.8rem">
          {label}
        </Typography>
      )}
      <ContainerFlex>
        <StyledInput {...props} />
        <Spacer orientation="horizontal" />
        <Typography color="textSecondary" size="1.1rem">
          {unit}
        </Typography>
      </ContainerFlex>
    </ContainerInput>
    {hint && (
      <>
        <Spacer spacing={0.5} />
        <ContainerFlex>
          <Spacer spacing={2} orientation="horizontal" />
          <Typography color="textSecondary" size="0.8rem">
            {hint}
          </Typography>
        </ContainerFlex>
      </>
    )}
  </>
);

export default Input;
