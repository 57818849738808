//const dataType = "packages";
const dataType = "amountsandimpact";
// const dataType = "";

const data = {
  minimumValue: 60,
  type: dataType,
  donations: [
    {
      amount: 275
    },
    {
      amount: 200
    },
    {
      amount: 100
    }
  ]
};

export default data;
