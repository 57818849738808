import React, { useState, useContext, createContext } from 'react';
import deepmerge from 'deepmerge';

import { ThemeProvider as StyledThemeProvider } from "styled-components";
import theme from '../theme';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [ themeObject, setThemeObject ] = useState(theme);

  const editTheme = (theme) => {
    setThemeObject((prev) => deepmerge(prev, theme));
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: themeObject,
        editTheme
      }}
    >
      <StyledThemeProvider theme={themeObject}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

const ThemeConsumer = ThemeContext.Consumer;

const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return context;
};

export {
  ThemeContext, ThemeProvider, ThemeConsumer, useThemeContext
};
