import React from "react";
import tinycolor from 'tinycolor2';
import styled, { css, keyframes } from "styled-components";

import { ReactComponent as LoadingIcon } from "../assets/loading.svg";
import Spacer from "./spacer";

const StyledButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  background-color: ${({ theme, disabled, isLoading }) =>
    (disabled || isLoading)
      ? tinycolor(theme.colors.primary).setAlpha(0.12).toRgbString()
      : theme.colors.primary
    };
  color: ${({ theme, disabled, isLoading }) =>
    (isLoading || disabled)
      ? tinycolor(theme.colors.primary).setAlpha(0.38).toRgbString()
      : "white"
    };
  &:active {
    background-color: ${({ theme }) => tinycolor(theme.colors.primary).darken(15)};
  }
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.25px;
  height: 48px;
  border-radius: 20px;
  width: 100%;
  cursor: ${({ disabled, isLoading }) => disabled || isLoading ? "default" : "pointer"};
  ${({ disabled, isLoading }) =>
    (disabled || isLoading) &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Icon = styled(LoadingIcon)`
  animation: ${rotation} 0.8s linear infinite;
  fill: ${({ theme }) => theme.colors.primary}
`;

const Button = ({ children, ...props }) => (
  <StyledButton {...props}>
    {props.isLoading && (
      <>
        <Icon height={26} alt="loading" />
        <Spacer spacing={1.5} orientation="horizontal" />
      </>
    )}
    {children}
  </StyledButton>
);

export default Button;
