import styled from "styled-components";
import Typography from "./typography";
import wayToBillLogo from "../assets/waytobill-logo-grey.webp";
import Spacer from "./spacer";

const Logo = styled.img`
  display: block;
  margin: auto;
`;

const Footer = () => (
  <>
    <Typography size="0.9rem" color="textSecondary">
    KFUMs betalningar i samarbete med
    </Typography>
    <Spacer spacing={0.5} />
    <Logo src={wayToBillLogo} width={110} />
  </>
);

export default Footer;
