import React from "react";
import styled from "styled-components";

const StyledTypography = styled.p`
  color: ${({ theme, color }) =>
    theme.colors[color] ?? color ?? theme.colors.textPrimary};
  font-size: ${({ size }) => size ?? "1rem"};
  font-weight: ${({ weight }) => weight ?? "normal"};
  margin: 0;
`;

const Typography = (props) => <StyledTypography {...props} />;

export default Typography;
