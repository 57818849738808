import React from "react";
import styled from "styled-components";
import { css } from "styled-components";

const StyledSpacer = styled.div`
  ${({ orientation, spacing }) =>
    orientation === "vertical"
      ? css`
          height: ${spacing * 8}px;
        `
      : css`
          width: ${spacing * 8}px;
        `}
`;

const Spacer = ({ orientation = "vertical", spacing = 1 }) => (
  <StyledSpacer orientation={orientation} spacing={spacing} />
);

export default Spacer;
