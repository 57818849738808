import React from "react";
import ReactDOM from "react-dom";
// import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from "./reportWebVitals";

import App from "./App";
// import Builder from "./Builder";
import { ThemeProvider } from "./context/ThemeContext";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      {/* <Router>
        <App />

        <Routes>
          <Route index path="/builder" element={<Builder />} />
        </Routes>
      </Router> */}

      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
