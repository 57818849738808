export const fetchPayment = async (payment) => {
  const response = await fetch(
    // TODO: Make sure this is the correct URL
    "https://charity.waytobill.com/kfum",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payment),
    }
  );
  return response;
};
