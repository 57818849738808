import styled from "styled-components";

const StyledCard = styled.div`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primaryLight : "white"};
  border: 2px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : theme.colors.border};
  padding: 16px;
  height: 180px;
  box-sizing: border-box;
  border-radius: 8px;
`;

const Card = (props) => <StyledCard {...props} />;

export default Card;
