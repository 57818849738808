const theme = {
  colors: {
    primary: "#450702",
    primaryLight: "#FEEBEB",
    primaryDark: "#A4373A",
    secondary: "",
    textPrimary: "black",
    textPrimaryLight: "rgba(0, 0, 0, 0.87)",
    textSecondary: "rgba(0, 0, 0, 0.6)",
    border: "rgba(0, 0, 0, 0.12)",
    hover: "rgba(0, 0, 0, 0.04)",
    focus: "rgba(0, 0, 0, 0.18)",
  },
};

export default theme;
