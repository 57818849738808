import { useState } from "react";
import { fetchPayment } from "../api";
import testData from "../data";

const useData = () => {
  const [data, setData] = useState();
  const [isPostDataDone, setIsPostDataDone] = useState(false);
  const [isPostDataLoading, setIsPostDataLoading] = useState(false);

  const fetchData = () => {
    setData(testData);
  };

  const postData = async (amount, productName, orderId) => {
    setIsPostDataLoading(true);

    try {
      const paymentObject = await generatePaymentDetails(amount, productName, orderId);
      const response = await fetchPayment(paymentObject);

      if (response.status === 200) {
        const responseData = await response.json();
        setData((prev) => ({
          ...prev,
          ...responseData
        }));
        setIsPostDataDone(true);
        window.open(responseData.checkoutUrl, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      console.log(error);
      alert("Request error");
    } finally {
      setIsPostDataLoading(false);
    }
  };

  const getStartDate = () => {
    const now = new Date();
    return (now.getDate() > 26
      ? new Date(now.getFullYear(),now.getMonth()+1,28,10,0,0,0)
      : new Date(now.getFullYear(),now.getMonth(),28,10,0,0,0)).toISOString().split("T")[0];
    }

  const generatePaymentDetails = (price, productName, orderId) => {
    const startDate = getStartDate();
    // const orderId = "KF" + Date.now().toString();
    return {
      campaignId: "77b4d915", // KFUM production: 77b4d915
      order: {
        id: orderId,
        type: "SUBSCRIPTION",
        periodicity: "MONTHLY",
        parts: 0,
        startDate,
        items: [
          {
            recurring: "ALL",
            article: {
              id: orderId,
              description: productName,
              value: price,
              vatPercentage: 0,
            },
            numberOfItems: 1,
          },
        ],
      }
    };
  };

  return {
    fetchData,
    data,
    postData,
    isPostDataDone,
    isPostDataLoading,
  };
};

export default useData;
