import Typography from "./typography";
import Spacer from "./spacer";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../assets/edit.svg";
import { useScreenClass } from "react-grid-system";
import { css } from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  border-radius: 24px;
  padding: 0 16px;
  letter-spacing: 0.4px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      justify-content: center;
    `}
  &:focus {
    background-color: ${({ theme }) => theme.colors.focus};
  }
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.primary};
  }
`;

const Icon = styled(EditIcon)`
  fill: ${({ theme }) => theme.colors.primary}
`;

const Link = ({ onClick, ...props }) => {
  const screenClass = useScreenClass();

  return (
    <Container
      onClick={onClick}
      isMobile={["xs", "sm", "md"].includes(screenClass)}
    >
      <Typography size="0.9rem" {...props} />
      <Spacer orientation="horizontal" />
      {<Icon width={16} alt="link icon" />}
    </Container>
  );
};

export default Link;
