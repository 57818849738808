import Typography from "./typography";
import Spacer from "./spacer";
import Card from "./card";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../assets/check.svg";
import { css } from "styled-components";

const ContainerIcon = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const StyledCard = styled(Card)`
  position: relative;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  height: 100%;
  ${({ type }) =>
    !type &&
    css`
      height: 56px;
      width: 100%;
    `}
  ${({ isActive }) =>
    !isActive &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
        border: 2px solid ${({ theme }) => theme.colors.hover};
      }
      &:focus {
        background-color: ${({ theme }) => theme.colors.focus};
        border: 2px solid ${({ theme }) => theme.colors.focus};
      }
    `}
`;

const Title = styled(Typography)`
  letter-spacing: 0.15px;
  ${({ shouldCenterContent }) =>
    shouldCenterContent &&
    css`
      text-align: center;
    `}
`;

const Subtitle = styled(Typography)`
  letter-spacing: 0.4px;
`;

const Icon = styled(CheckIcon)`
  fill: ${({ theme }) => theme.colors.primary}
`;

const DataCard = (props) => {
  const {
    item,
    isActive,
    onClick,
    type,
    children,
    ...other
  } = props;

  const hasOnlyAmount = item.amount && !item.bigTitle && !item.subtitle && !item.description;

  return (
    <StyledCard onClick={onClick} isActive={isActive} type={type} {...other}>
      {isActive && (
        <ContainerIcon>
          <Icon alt="checkmark" />
        </ContainerIcon>
      )}
      {hasOnlyAmount && (
        <Title
          style={{ textAlign: 'center' }}
          color="textPrimaryLight"
          weight={600}
          shouldCenterContent={!type}
        >
          <>{item.amount}{item.currency && ` ${item.currency}`}</>
        </Title>
      )}
      {!hasOnlyAmount && (
        <>
          {item?.bigTitle && type === "packages" && (
            <>
              <Typography color="textPrimaryLight" size="1.25rem" weight={600}>
                {item.bigTitle}
              </Typography>
              <Spacer spacing={2} />
            </>
          )}
          {item?.amount && (
            <>
              <Title
                color="textPrimaryLight"
                weight={600}
                shouldCenterContent={!type}
              >
                <>{item.amount}{item.currency && ` ${item.currency}`}</>
              </Title>
              <Spacer spacing={2} />
            </>
          )}
          {item?.subtitle && type && (
            <>
              <Subtitle size="0.9rem" color="textSecondary">
                {item.subtitle}
              </Subtitle>
              <Spacer />
            </>
          )}
          {item?.description && type && <Typography>{item.description}</Typography>}
        </>
      )}
      {children}
    </StyledCard>
  );
};

export default DataCard;
