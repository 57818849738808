import React, { useEffect, useState } from "react";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";

import Button from "./components/button";
import Typography from "./components/typography";
import Spacer from "./components/spacer";
// import TabHeader from "./components/tab-header";
import DataCard from "./components/data-card";
import Link from "./components/link";
import Input from "./components/input";
import Footer from "./components/footer";
import useData from "./hooks/useData";
// import SuccessModal from "./components/success-modal";

// const tabs = ["Månadsgivare"]; // ["Monthly", "One-time"];

const App = () => {
  const { fetchData, data, postData, isPostDataDone, isPostDataLoading } =
    useData();

  const [selectedDataIndex, setSelectedDataIndex] = useState(1);
  const [selectedTabIndex] = useState(0);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [fetchData, data]);

  useEffect(() => {
    if (Array.isArray(data?.donations) && data.donations[0].amount) {
      setInputValue(data.donations[0].amount);
    }
  }, [data]);

  useEffect(() => {
    if (!window.location.search) return;

    const params = new URLSearchParams(window.location.search);
    const ga = params.get('_ga');
    const gid = params.get('_gid');

    if (ga && gid) {
      document.cookie = `_ga=${ga};domain=waytobill.com;path=/`;
      document.cookie = `_gid=${gid};domain=waytobill.com;path=/`;
    }
  }, []);

  /* const handleTabClick = (index) => {
    setSelectedTabIndex(index);
  }; */

  const handleDataCardClick = (index) => {
    setSelectedDataIndex(index);
  };

  const handleInputChange = ({ target }) => {
    const value = target.value.replace(/\s/g, "");

    if (!value || /^\d+$/.test(value)) {
      setInputValue(value);
    }
  };

  const handleLinkClick = () => {
    setSelectedDataIndex(undefined);
  };

  const handleSubmit = async () => {
    const amount = parseInt(
      typeof selectedDataIndex === "number"
        ? data.donations[selectedDataIndex]?.amount
        : parseInt(inputValue)
    );
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get('ItemDescription')
    const orderId = urlParams.get('OrderId')
    postData(amount, product || "Månadsgivande", orderId);
  };

  const handleRedirect = () => {
    window.open(data.checkoutUrl, "_blank", "noopener,noreferrer");
  };

  const showInputCard = typeof selectedDataIndex === "undefined";

  if (!data) return <div>Loading...</div>;

  // const minAmount = Array.isArray(data.donations)
  //   ? data.donations[0].amount
  //   : 0;
  const minAmount = data.minimumValue;

  return (
    <Container style={{ width: "100vw" }}>
      {/* isPostDataDone && <SuccessModal /> */}
      {/* <Row justify="center">
        <Col xs="content">
          <Typography color="textSecondary" size="0.9rem">
          Välj gåvotyp.
          </Typography>
        </Col>
      </Row>
      <Spacer spacing={3} />
      <Row justify="center">
        <Col xs={11} sm={9} md={5}>
          <div>
            <TabHeader
              tabs={tabs}
              activeIndex={selectedTabIndex}
              onTabClick={handleTabClick}
            />
          </div>
        </Col>
      </Row> */}
      <Spacer spacing={6} />
      <Row justify="center">
        <Col xs="content">
          <Typography color="textSecondary" size="0.9rem">
            Välj månadsbelopp.
          </Typography>
        </Col>
      </Row>
      <Spacer spacing={3} />
      <Row>
        {data?.donations?.map((item, index) => (
          <React.Fragment key={item.amount}>
            <Col xs={12} md={12 / data.donations.length}>
              <DataCard
                item={{ currency: 'kr', ...item }}
                isActive={index === selectedDataIndex}
                onClick={() => handleDataCardClick(index)}
                type={selectedTabIndex === 1 ? undefined : data.type}
              />
            </Col>
            <Visible xs sm>
              <Col>
                <Spacer spacing={2} />
              </Col>
            </Visible>
          </React.Fragment>
        ))}
      </Row>
      <Row justify="center">
        <Col md={showInputCard ? 6 : "content"}>
          {showInputCard ? (
            <>
              <Hidden xs sm>
                <Spacer spacing={2} />
              </Hidden>
              <DataCard
                isActive
                item={{ amount: "Hur mycket vill du skänka?" }}
                type="packages"
              >
                <Spacer />
                <Input
                  hint={`Minimibeloppet är ${minAmount} kr.`}
                  label="belopp"
                  unit="kr"
                  value={
                    inputValue
                      ? new Intl.NumberFormat("sv-SE").format(inputValue)
                      : inputValue
                  }
                  onChange={handleInputChange}
                />
              </DataCard>
            </>
          ) : (
            <>
              <Spacer spacing={4} />
              <Link onClick={handleLinkClick} color="primary">
              Valfritt belopp
              </Link>
            </>
          )}
        </Col>
      </Row>
      <Spacer spacing={4} />
      <Row justify="center">
        <Col xs={12} md={4}>
          {!isPostDataDone && (
            <Button
              disabled={
                showInputCard ? !(parseInt(inputValue) >= minAmount) : false
              }
              onClick={handleSubmit}
              isLoading={isPostDataLoading}
            >
              Gå vidare till anmälan
            </Button>
          )}
          {isPostDataDone && (
            <Button onClick={handleRedirect}>
              Klicka igen om sidan inte öppnas
            </Button>
          )}
        </Col>
      </Row>
      <Spacer spacing={3} />
      <Row justify="center">
        <Col xs="content">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default App;
